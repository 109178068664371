import { LoginInfo } from './../../main';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn, mapToCanActivate } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService extends KeycloakAuthGuard  {

  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private authSvc: AuthService) {
      super(router, keycloak);
    }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
        const redirectBackTo = this.authSvc.getRedirectBack() ?? window.location.origin + state.url;
        this.authSvc.setRedirectBack(undefined);
        await this.keycloak.login({
        redirectUri: redirectBackTo
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.hasRole(role));
  }

  public logout(): Promise<void> {
    this.authSvc.setRedirectBack(location.protocol + '//' + location.host);
    this.authSvc.setIdUtenteLoggato(undefined);
    this.authenticated = false;
    return this.keycloak.logout();
  }

  public hasRole(role: string): boolean {
    if (typeof this.roles != 'undefined') {
      return this.roles.includes(role);
    }
    return false;
  }
}

export const canActivate: CanActivateFn[] = mapToCanActivate([RouteGuardService]);
