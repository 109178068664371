import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, mapToCanActivate, UrlTree, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MonitorGuard {

  constructor(
    private readonly router: Router,
    private authSvc: AuthService) {
    }

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const clientId = route.queryParams.clientId;
    const clientSecret = route.queryParams.clientSecret;

    if (this.notNullish(clientId) && this.notNullish(clientSecret)) {
      this.authSvc.setClientCredentials(clientId, clientSecret);
    }

    return new Promise(async (res, _rej) => {
      const ct = await this.authSvc.getClientToken();
      res(this.notNullish(ct));
    });
  }

  notNullish(obj: any): boolean {
    return typeof obj !== 'undefined' && obj !== null && obj;
  }

}

export const canActivate: CanActivateFn[] = mapToCanActivate([MonitorGuard]);
