/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './baseservice';
import { LOGIC_BASE } from './endpoints';
import { SSE, SubscribedEndpoint } from './sse';

export abstract class NetworkBug {
  constructor(private active: boolean) {}

  abstract getMessage(): string;

  public isActive() {
    return this.active;
  }
}
class NetworkUnreliableBug extends NetworkBug {
  getMessage(): string {
    return 'La connessione è lenta o instabile';
  }
}

class NetworkDisconnectedBug extends NetworkBug {
  getMessage(): string {
    return 'Dispositivo disconnesso: in attesa di riconnessione';
  }
}

export class SubscriptionBug extends NetworkBug {

  constructor(public mancantiLatoServer: SubscribedEndpoint[],
              public mancantiLatoClient: SubscribedEndpoint[]) {
      super(mancantiLatoServer.length + mancantiLatoClient.length > 0);
  }

  getMessage(): string {
    return 'Differenza di stato client/server non prevista';
  }
}

@Injectable({
  providedIn: 'root',
})
export class NetworkDebugService extends BaseService<SubscribedEndpoint> {
  private reteInstabile = new Subject<NetworkUnreliableBug>();
  private problemiConnessione = new Subject<NetworkDisconnectedBug>();
  private subscriptionsNonCoincidenti = new Subject<SubscriptionBug>();

  getRuoloDebug(): Observable<string> {
    return this.hget<string>(LOGIC_BASE + '/opzioni/insecuredebugmode');
  }

  updateRuoloDebug(ruolo: string): Observable<string> {
    return this.hput<string>(
      LOGIC_BASE + `/opzioni/insecuredebugmode/${ruolo}`,
      {}
    );
  }

  getSubscribedEndpoints(): Observable<SubscribedEndpoint[]> {
    return this.hget<SubscribedEndpoint[]>(
      LOGIC_BASE +
        '/opzioni/insecuredebugmode/subscriptions/' +
        SSE.getSessionId()
    );
  }

  getReteInstabile(): Observable<NetworkBug> {
    return this.reteInstabile;
  }

  segnalaReteInstabile() {
    this.reteInstabile.next(new NetworkUnreliableBug(true));
  }

  segnalaReteStabile() {
    this.reteInstabile.next(new NetworkUnreliableBug(false));
  }

  getProblemiConnessione(): Observable<NetworkBug> {
    return this.problemiConnessione;
  }

  segnalaConnessioneOk() {
    this.problemiConnessione.next(new NetworkDisconnectedBug(false));
  }

  segnalaConnessioneKo() {
    this.problemiConnessione.next(new NetworkDisconnectedBug(true));
  }

  getProblemiSubscriptions(): Observable<SubscriptionBug> {
    return this.subscriptionsNonCoincidenti;
  }

  segnalaProblemiSubscriptions(bug: SubscriptionBug) {
    this.subscriptionsNonCoincidenti.next(bug);
  }
}
