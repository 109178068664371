/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, map } from 'rxjs';
import { Entity } from './crud.service';
import { PushService } from './push.service';
import { ChannelMode } from './socketio';

export interface Opzione extends Entity {
  tabella: string;
  riga: string;
  chiave: string;
  valore: string;
  descrizione?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OpzioniService extends PushService<Opzione>{

  constructor() {
    super('opzione');
  }

  fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  getOpzioneByRigaChiave(idRiga: string, chiave: string): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.baseUrl}/rigachiave/${idRiga}/${chiave}`);
  }

  getOpzioneByRigaChiaveSse(idRiga: string, chiave: string, compId: string): Observable<Opzione[]> {
    return this.getSSE(`${this.baseUrl}/rigachiave/${idRiga}/${chiave}`, compId);
  }


  getCached(endpoint: string): Promise<string> {
    /* tiene in cache il tema per non fare una chiamata ogni volta, tanto fondamentalemnte non cambia mai */
    const valoreInCache = window.sessionStorage.getItem(endpoint);
    if (valoreInCache === null) {
      const opzObs = this.hget<string>(endpoint)
      .pipe(map(val => {
        window.sessionStorage.setItem(endpoint, val);
        return val;
      } ));
      return firstValueFrom(opzObs);
    } else {
      return Promise.resolve(valoreInCache);
    }
  }

  getTemaWebapp(): Promise<string> {
    /* tiene in cache il tema per non fare una chiamata ogni volta, tanto fondamentalemnte non cambia mai */
    return this.getCached(`${this.logicBase}/opzioni/temawebapp`);
  }

  getMillisVisualChiamataMonitor(idSede: string): Promise<string> {
    return this.getCached(`${this.logicBase}/opzioni/monitor/tempominimochiamata/${idSede}`);
  }

  getTemaDindonMonitor(idSede: string): Promise<string> {
    return this.getCached(`${this.logicBase}/opzioni/monitor/dindon/${idSede}`);
  }

  //il parametro 'idSede' al momento è null, sarà da specificare quando verrà implementata la possibilità di avere colori diversi per lo stesso servizio in base alla sede
  getColoreServizio(idServizio: string, idSede = null): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.logicBase}/opzioni/servizi/colore/${idServizio}`);
  }

  getColoreServizioSSE(idServizio: string, compId: string, idSede = null): Observable<Opzione[]> {
    const url = `${this.logicBase}/opzioni/servizi/colore/${idServizio}`;
    return this.getSSE(url, compId);
  }

  getColoreServizioSIO(idServizio: string, idSede = null): Observable<Opzione[]> {
    const url = `${this.logicBase}/opzioni/servizi/colore/${idServizio}`;
    return this.getIO(url, { channel: 'opzioneController', mode: ChannelMode.UPDATES_ONLY } )
                .pipe(map(er => er.map(e => e.payload)));
  }

  //il parametro 'idSede' al momento è null, sarà da specificare quando verrà implementata la possibilità di avere colori diversi per lo stesso servizio in base alla sede
  setColoreServizio(idServizio: string, codiceColoreCss: string, idSede = null): Observable<string> {
    const colore = encodeURIComponent(codiceColoreCss);
    return this.hput<string>(`${this.logicBase}/opzioni/servizi/colore/${idServizio}/${colore}`, {});
  }

  getIntestazioneTicket(lang: string = 'it'): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.logicBase}/tickets/stampa/intestazione?lang=${lang}`);
  }

  updateIntestazioneTicket(testo: string, lang: string = 'it'): Observable<string> {
    if (testo) {
      const testoEncode = this.fixedEncodeURIComponent(testo);
      return this.hput<string>(`${this.logicBase}/tickets/stampa/intestazione?intestazione=${testoEncode}&lang=${lang}`, {});
    } else {
      return this.hput<string>(`${this.logicBase}/tickets/stampa/intestazione?lang=${lang}`, {});
    }
  }

  getTestoTicketServizio(idServizio: string, lang: string = 'it'): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.logicBase}/servizi/ticket/testo/${idServizio}?lang=${lang}`);
  }

  updateTestoTicketServizio(idServizio: string, testo: string, lang: string = 'it'): Observable<string> {
    if (testo) {
      const testoEncode = this.fixedEncodeURIComponent(testo);
      return this.hput<string>(`${this.logicBase}/servizi/ticket/testo/${idServizio}?testo=${testoEncode}&lang=${lang}`, {});
    } else {
      return this.hput<string>(`${this.logicBase}/servizi/ticket/testo/${idServizio}?lang=${lang}`, {});
    }
  }

  getTestoTotemServizio(idServizio: string, aperto: number, lang: string = 'it'): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.logicBase}/servizi/totem/testo/${idServizio}/${aperto}?lang=${lang}`);
  }

  getTestoTotemServizioSSE(idServizio: string, aperto: number, compId: string, lang: string = 'it'): Observable<Opzione[]> {
    const url = `${this.logicBase}/servizi/totem/testo/${idServizio}/${aperto}?lang=${lang}`;
    return this.getSSE(url, compId);
  }

  getTestoTotemServizioSIO(idServizio: string, aperto: number, lang: string = 'it'): Observable<Opzione[]> {
    const url = `${this.logicBase}/servizi/totem/testo/${idServizio}/${aperto}?lang=${lang}`;
    return this.getIO(url, { channel: 'opzioneController', mode: ChannelMode.UPDATES_ONLY } )
                .pipe(map(er => er.map(e => e.payload)));
  }

  updateTestoTotemServizio(idServizio: string, aperto: number, testo: string, lang: string = 'it'): Observable<string> {
    if (testo) {
      const testoEncode = this.fixedEncodeURIComponent(testo);
      return this.hput<string>(`${this.logicBase}/servizi/totem/testo/${idServizio}/${aperto}?testo=${testoEncode}&lang=${lang}`, {});
    } else {
      return this.hput<string>(`${this.logicBase}/servizi/totem/testo/${idServizio}/${aperto}?lang=${lang}`, {});
    }
  }

  getTestoInfoServizioTotem(idServizio: string, lang: string = 'it'): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.logicBase}/servizi/totem/testoinfo/${idServizio}?lang=${lang}`);
  }

  getTestoInfoServizioTotemSSE(idServizio: string, compId: string, lang: string = 'it'): Observable<Opzione[]> {
    const url = `${this.logicBase}/servizi/totem/testoinfo/${idServizio}?lang=${lang}`;
    return this.getSSE(url, compId);
  }

  getTestoInfoServizioTotemSIO(idServizio: string, lang: string = 'it'): Observable<Opzione[]> {
    const url = `${this.logicBase}/servizi/totem/testoinfo/${idServizio}?lang=${lang}`;
    return this.getIO(url, { channel: 'opzioneController', mode: ChannelMode.UPDATES_ONLY } )
                .pipe(map(er => er.map(e => e.payload)));
  }

  updateTestoInfoServizioTotem(idServizio: string, testo: string, lang: string = 'it'): Observable<string> {
    if (testo) {
      const testoEncode = this.fixedEncodeURIComponent(testo);
      return this.hput<string>(`${this.logicBase}/servizi/totem/testoinfo/${idServizio}?testo=${testoEncode}&lang=${lang}`, {});
    } else {
      return this.hput<string>(`${this.logicBase}/servizi/totem/testoinfo/${idServizio}?lang=${lang}`, {});
    }
  }

  getTicketInfinitiServizio(idSede: string, idServizio: string): Observable<boolean> {
    return this.hget<boolean>(`${this.logicBase}/servizi/ticketinfiniti/${idSede}/${idServizio}`);
  }

  updateTicketInfinitiServizio(idSede: string, idServizio: string, attiva: boolean): Observable<boolean> {
    return this.hput<boolean>(`${this.logicBase}/servizi/ticketinfiniti/${idSede}/${idServizio}/${attiva}`, {});
  }

  getAccensioneMonitor(idMonitor: string, giorno: string): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.logicBase}/opzioni/monitor/accensione/${idMonitor}/${giorno}`);
  }

  updateAccensioneMonitor(idMonitor: string, giorno: string, oreminuti: string): Observable<string> {
    return this.hput<string>(`${this.logicBase}/opzioni/monitor/accensione/${idMonitor}/${giorno}/${oreminuti}`, {});
  }

  getSpegnimentoMonitor(idMonitor: string, giorno: string): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.logicBase}/opzioni/monitor/spegnimento/${idMonitor}/${giorno}`);
  }

  updateSpegnimentoMonitor(idMonitor: string, giorno: string, oreminuti: string): Observable<string> {
    return this.hput<string>(`${this.logicBase}/opzioni/monitor/spegnimento/${idMonitor}/${giorno}/${oreminuti}`, {});
  }

  getWorkflowPrenIstantaneaServizio(idSede: string, idServizio: string): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.logicBase}/opzioni/servizi/workflows/prenotazioneistantanea/${idSede}/${idServizio}`);
  }

  updateWorkflowPrenIstantaneaServizio(idSede: string, idServizio: string, attiva: boolean): Observable<Opzione[]> {
    return this.hput<Opzione[]>(`${this.logicBase}/opzioni/servizi/workflows/prenotazioneistantanea/${idSede}/${idServizio}/${attiva}`, {});
  }

  getWorkflowPrenIstantaneaPlugin(idSede: string): Observable<Opzione[]> {
    return this.hget<Opzione[]>(`${this.logicBase}/opzioni/plugins/workflows/prenotazioneistantanea/${idSede}`);
  }

  updateWorkflowPrenIstantaneaPlugin(idSede: string, attivo: boolean): Observable<Opzione[]> {
    return this.hput<Opzione[]>(`${this.logicBase}/opzioni/plugins/workflows/prenotazioneistantanea/${idSede}/${attivo}`, {});
  }
}
