import { Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

export interface AuthorizationHttpOption {
  headers: {
    Authorization: string
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService<T> {
  private base_ng_http = inject(HttpClient);
  private base_auth = inject(AuthService);

  public addBearerToken(options?: any): AuthorizationHttpOption {
    if (!options) {
      options = {
        headers: {
          Authorization: 'Bearer ' + this.base_auth.getBearerToken()
        }
      };
    }
    if (!options.headers) {
      options.headers = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: 'Bearer ' + this.base_auth.getBearerToken()
      };
    } else {
      if (!options.headers.Authorization) {
        options.headers.Authorization = 'Bearer ' + this.base_auth.getBearerToken();
      }
    }
    return options;
  }

  hget<R extends (T | T[]) | string | string[] | boolean>(url: string, options?: any): Observable<R> {
    return this.base_ng_http.get<R>(url, this.addBearerToken(options));
  }

  hput<R>(url: string, body: Partial<T>, options?: any): Observable<R> {
    return this.base_ng_http.put<R>(url, body, this.addBearerToken(options));
  }

  hpost<R>(url: string, body: Partial<T>, options?: any): Observable<R> {
    return this.base_ng_http.post<R>(url, body, this.addBearerToken(options));
  }

  hdelete<R>(url: string, options?: any): Observable<R> {
    return this.base_ng_http.delete<R>(url, this.addBearerToken(options));
  }
}
