import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment.prod';
import { KeycloakService } from 'keycloak-angular';
import { LoginInfo } from '../main';
import { GlobalErrorHandler } from './global-error-handler';

import { LowerCasePipe, TitleCasePipe, UpperCasePipe, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { ReplaySubject } from 'rxjs';
registerLocaleData(localeIt);

export const bearerToken = new ReplaySubject<string>(1);

const initializeKeycloak = (keycloak: KeycloakService) => async () =>
    keycloak.init({
      config: {
        url: (JSON.parse(sessionStorage.getItem('loginInfo')) as LoginInfo).baseUrl,
        realm: (JSON.parse(sessionStorage.getItem('loginInfo')) as LoginInfo).realm,
        clientId: (JSON.parse(sessionStorage.getItem('loginInfo')) as LoginInfo).client,
      },
      initOptions: {
        flow: 'hybrid',
        checkLoginIframe: false,
      },
      shouldAddToken: (_request) => true
    }).then(async (authenticated) => {
      if (authenticated) {
        bearerToken.next(await keycloak.getToken());
      }});
@NgModule({
    declarations: [AppComponent],
    imports: [FormsModule,
              BrowserModule,
              IonicModule.forRoot(),
              AppRoutingModule,
              HttpClientModule,
              BrowserAnimationsModule,
              CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
              NgxPaginationModule,
              NgxGoogleAnalyticsModule.forRoot(environment.ga4MeasurementId),
            ],
    providers: [KeycloakService,
      {
        provide: APP_INITIALIZER,
        useFactory: initializeKeycloak,
        multi: true,
        deps: [KeycloakService]
      }, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      /*{
        // processes all errors
        provide: ErrorHandler,
        useClass: GlobalErrorHandler,
      },*/
      { provide: LOCALE_ID, useValue: 'it-IT'},
      TitleCasePipe,
      UpperCasePipe,
      LowerCasePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
