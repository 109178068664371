/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { LOGIC_BASE } from './endpoints';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private static idUtenteLoggatoKey = 'idUtenteLoggato';
  private static bearerTokenKey = 'bearerToken';
  private static clientIdKey = 'clientId';
  private static clientSecretKey = 'clientSecret';
  private static redirectBackKey = 'redirectBack';
  private tokenSet: any;

  constructor() { }

  getIdUtenteLoggato() {
    return StorageService.sGet(AuthService.idUtenteLoggatoKey);
  }

  setIdUtenteLoggato(u: string | undefined) {
    StorageService.sSet(AuthService.idUtenteLoggatoKey, u);
  }

  getBearerToken() {
    return StorageService.sGet(AuthService.bearerTokenKey);
  }

  setBearerToken(t: string | undefined) {
    StorageService.sSet(AuthService.bearerTokenKey, t);
  }

  setClientCredentials(clientId: string, clientSecret: string) {
    this.expireClientToken();
    StorageService.sSet(AuthService.clientIdKey, clientId);
    StorageService.sSet(AuthService.clientSecretKey, clientSecret);
  }

  expireClientToken() {
    this.tokenSet = null;
    this.setBearerToken('');
  }

  public async getClientToken() {
    if (this.ccTokenExpired()) {
      // Token doesn't exist or has expired, request a new one
      const response = await this.getClientCredentialsGrantToken();
      if (response.status === 200) {
        this.tokenSet = await response.json();
        const now = new Date().getTime();
        this.tokenSet.expiry = now + 1000 * this.tokenSet.expires_in;
        this.setBearerToken(this.tokenSet.access_token);
        const expiresIn = this.tokenSet.expiry - now - 10 * 1000;
        setTimeout(() => {
          this.expireClientToken();
          this.getClientToken();
        }, expiresIn);
      }
    }

    return this.getBearerToken();
  }

  private ccTokenExpired(): boolean {
    return (!this.tokenSet) || ((new Date().getTime() / 1000) >= this.tokenSet.expiry);
  }

  private async getClientCredentialsGrantToken(): Promise<Response> {
    const loginInfoPath = '/auth/loginInfo/nowhere.html';
    const infoEndpoint = LOGIC_BASE + loginInfoPath;

    const loginInfo = {
      baseUrl: '',
      url: '',
      ccgUrl: '',
      realm: '',
      client: '',
      profilesDomain: ''
    };

    const resp = await fetch(infoEndpoint);
    if (resp.status === 200) {
      Object.assign(loginInfo, await resp.json());
    }

    const data = new URLSearchParams();
    data.append('client_id', StorageService.sGet(AuthService.clientIdKey));
    data.append('client_secret', StorageService.sGet(AuthService.clientSecretKey));
    data.append('grant_type', 'client_credentials');

    return fetch(loginInfo.ccgUrl, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow',
      body: data,
    });
  }

  public setRedirectBack(rb: string | undefined) {
    StorageService.lSet(AuthService.redirectBackKey, rb);
  }

  public getRedirectBack(): string | undefined {
    return StorageService.lGet(AuthService.redirectBackKey);
  }
}
