/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public static sGet(key: string): string | undefined {
    const stored = sessionStorage.getItem(key);
    return stored ?? undefined;
  }

  public static sSet(key: string, val: string | undefined) {
    if (typeof val === 'undefined') {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, val);
    }
  }

  public static lGet(key: string): string | undefined {
    const stored = localStorage.getItem(key);
    return stored ?? undefined;
  }

  public static lSet(key: string, val: string | undefined) {
    if (typeof val === 'undefined') {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, val);
    }
  }
}
