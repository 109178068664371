import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { BaseService } from './baseservice';
import { CRUD_BASE, LOGIC_BASE } from './endpoints';

export interface Entity {
  id?: string;
  createAt?: string;
  updateAt?: string;
  deleteAt?: string;
}

@Injectable({
  providedIn: 'root'
})
export abstract class CrudService<T> extends BaseService<T> {
  protected baseUrl: string;
  protected crudBase = CRUD_BASE;
  protected logicBase = LOGIC_BASE;

  constructor(path: string) {
    super();
    this.baseUrl = CRUD_BASE + '/' + path;
  }

  getAll(): Observable<T[]> {
    return this.hget<T[]>(this.baseUrl);
  }

  byId(id: string): Observable<T> {
    return this.hget<T>(`${this.baseUrl}/${id}`);
  }

  byIds(ids: string[]): Observable<T[]> {
    const j = ids.join(',');
    return this.hget<T[]>(`${this.baseUrl}/byIds/${j}`);
  }

  update(id: string, body: Partial<T>): Promise<string> {
    return firstValueFrom(this.hput<string>(`${this.baseUrl}/${id}`, body));
  }

  create(body: Partial<T>): Promise<string> {
    return firstValueFrom(this.hpost<string>(this.baseUrl, body));
  }

  delete(id: string): Promise<string> {
    return firstValueFrom(this.hdelete<string>(`${this.baseUrl}/${id}`));
  }

}
